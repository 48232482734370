<template>
  <v-container fluid class="pa-0">
    <div v-for="(demo, k_i) of additionalDemographics" :key="k_i">
      <v-list-item>  
        <v-list-item-content>
          <v-list-item-title v-text="demo.title"></v-list-item-title>
          <v-list-item-subtitle v-html="demo.answer"></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
    </div>

    <NoInfo v-if="additionalDemographics.length == 0" />
  </v-container>
</template>

<script>
import NoInfo from './../../components/NoInfo'

export default {
  components: {
    NoInfo
  },
  data() {
    return {
      additionalDemographics: this.$store.state.userProfile.additionalDemographics,
    }
  },
};
</script>